body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'TTCommonsTR';
  src: local('TTCommonsTR'),
    url(./fonts/TTCommonsTrialRegular.otf) format('opentype');
}

@font-face {
  font-family: 'TTCommonsTRLight';
  src: local('TTCommonsTRLight'),
    url(./fonts/TTCommonsTrialLight.otf) format('opentype');
}
@font-face {
  font-family: 'TTCommonsTRBold';
  src: local('TTCommonsTRBold'),
    url(./fonts/TTCommonsTrialBold.otf) format('opentype');
}

/* This keeps child nodes hidden while the element loads */
:not(:defined) > * {
  display: none;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}
